import React from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { IWidgetConfigurationContext, WidgetConfigurationContext } from '../../common/configuration';
import { SgwtWidgetName, startWidgetMonitoring } from '../../common/monitoring';
import { whichEnvironment } from '../../common/sgwt-widgets-utils';
import { widgetize, widgetPropsBoundEvent } from '../../common/widgetize';
import PlaygroundViewer from './components/PlaygroundViewer';
import {
  DEFAULT_CODE_HEIGHT,
  DEFAULT_IFRAME_HEIGHT,
  DEFAULT_PREVIEW_HEIGHT,
  SgmDesignLab,
  SgmDesignLabConfiguration,
  SgwtPlaygroundViewerProps,
} from './sgwt-playground-viewer.types';

export class SgwtPlaygroundViewer extends React.Component<SgwtPlaygroundViewerProps> {
  static readonly contextType = WidgetConfigurationContext;
  public static readonly is = 'sgwt-playground-viewer';
  private propsBoundEvent = widgetPropsBoundEvent(SgwtPlaygroundViewer.is);
  private widgetConfiguration: WidgetConfiguration;
  private sgmDesignLabConfiguration: SgmDesignLabConfiguration;

  constructor(props: SgwtPlaygroundViewerProps, context: IWidgetConfigurationContext) {
    super(props);
    this.widgetConfiguration = context!.widgetConfiguration;
    const env = whichEnvironment(this.widgetConfiguration);
    this.sgmDesignLabConfiguration = SgmDesignLab[env];
  }

  private onWidgetReady = (): void => {
    this.props.onReady();
  };

  componentDidMount(): void {
    document.addEventListener(this.propsBoundEvent, this.onWidgetReady);
    startWidgetMonitoring(SgwtWidgetName.MINI_FOOTER, null, {
      playgroundId: this.props.playgroundId,
      showCode: this.props.showCode,
      useIframe: this.props.useIframe,
    });
  }

  componentWillUnmount = (): void => {
    document.removeEventListener(this.propsBoundEvent, this.onWidgetReady);
  };

  render() {
    const height = this.props.codeHeight ?? this.props.useIframe ? DEFAULT_IFRAME_HEIGHT : DEFAULT_PREVIEW_HEIGHT;
    const { playgroundId } = this.props;

    if (!playgroundId || playgroundId === '') {
      return (
        <div className="p-5 alert alert-discreet-warning">
          <p>The ID of the Playground to display is not provided.</p>
        </div>
      );
    }

    return (
      <PlaygroundViewer
        {...this.props}
        codeHeight={this.props.codeHeight ?? DEFAULT_CODE_HEIGHT}
        previewHeight={height}
        sgmDesignLabConfiguration={this.sgmDesignLabConfiguration}
        widgetConfiguration={this.widgetConfiguration}
      />
    );
  }
}

widgetize(
  SgwtPlaygroundViewer,
  SgwtPlaygroundViewer.is,
  {
    attributes: [
      { name: 'code-class', type: 'string' },
      { name: 'code-height', type: 'string' },
      { name: 'container-class', type: 'string' },
      { name: 'playground-id', type: 'string' },
      { name: 'preview-class', type: 'string' },
      { name: 'preview-height', type: 'string' },
      { name: 'show-code', type: 'boolean' },
      { name: 'use-iframe', type: 'boolean' },
    ],
    events: [
      {
        name: `${SgwtPlaygroundViewer.is}--ready`,
        functionName: 'onReady',
      },
    ],
  },
  { shadow: false },
);
